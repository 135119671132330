<template>
  <div style="padding-bottom: 0px">
    <Breadcrumb
      :home="false"
      :breadcrumbs="[
        {
          label: '订单管理',
          to: '/order',
        },
        {
          label: '详情',
          to: `/order/${this.$route.params.id}`,
        },
      ]"
    />
    <el-card class="card-common">
      <template #header>基本信息</template>
      <InfoPanel :schema="orderDetailSchema" :data="orderDetailData">
        <template #name="{ value }">
          <div>{{ value }}</div>
          <el-link type="primary" class="edit-btn" @click="onEdit">
            编辑
          </el-link>
        </template>
      </InfoPanel>
      <el-dialog title="编辑" :visible.sync="editDialogVisible" width="30%">
        <el-form :model="orderDetailData" label-width="80px">
          <el-form-item label="请输入订单名称" style="margin: 0">
            <el-input v-model="orderDetailData.name" />
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onEditSubmit">提交</el-button>
        </span>
      </el-dialog>
    </el-card>
    <el-card class="card-common">
      <template #header>订单进度</template>
      <div class="process-progress">
        <OrderProgress
          :orderId="orderId"
          :processData="processData"
        ></OrderProgress>
      </div>
    </el-card>
    <el-card class="card-common">
      <template #header>MAC/SN</template>
      <div class="mac-sn-wall">
        <MACSN :orderId="orderId" />
      </div>
    </el-card>
    <el-card class="card-common">
      <template #header>制程图</template>
      <div class="process-wall">
        <WorkflowScroll :processData="processData" />
      </div>
    </el-card>
    <div
      style="
        height: 72px;
        background-color: #fff;
        margin: 10px -20px -32px -20px;
        line-height: 72px;
      "
    >
      <el-button
        type="primary"
        @click="manualFinishConfirm()"
        style="margin-left: 40px"
        >手动完成</el-button
      >
    </div>
  </div>
</template>

<style>
.card-common {
  margin-top: 20px;
}
.edit-btn {
  margin-left: 10px;
}

.el-card__header {
  border: none;
  padding-bottom: 0;
}
</style>

<script>
import axios from "axios";
import { uris, attachId } from "./../../api/uri";

import InfoPanel from "enn-info-panel";
import orderDetailSchema from "./order-detail.schema.json";

import { SchemaTable, DataModel } from "enn-schema-table";
import FormRender from "enn-schema-form-render";

import { DraggableList, DraggableListItem } from "enn-draggable-list";
import Procedure from "../procedure/index.vue";
import Breadcrumb from "enn-breadcrumb";
import OrderProgress from "./lineProgress/index.vue";
import MACSN from "./macsn/index.vue";
import moment from "moment";
import WorkflowScroll from "./workflow-scroll/index.vue";

export default {
  components: {
    InfoPanel,
    SchemaTable,
    FormRender,
    DraggableList,
    DraggableListItem,
    Procedure,
    Breadcrumb,
    OrderProgress,
    MACSN,
    WorkflowScroll,
  },
  data() {
    const { id } = this.$route.params;
    return {
      orderId: id,
      editDialogVisible: false,
      constStageName: {
        1: "量产",
        2: "试产",
        3: "测试",
      },
      orderDetailSchema,
      orderDetailData: {},

      MACNum: 0,
      SNNum: 0,
      workflowId: null,
      groupId: null,

      processData: [],
    };
  },
  created() {
    this.getOrder();
  },
  methods: {
    // 获取订单详情
    getOrder() {
      const { id: orderId } = this.$route.params;
      axios.get(`${uris.order}/${this.orderId}`).then((res) => {
        const {
          data: { data: order },
        } = res;
        order.id = orderId;
        order.created_at = this.dateFormat(order.created_at);
        order.productName = order.product?.name;
        order.productId = order.product?.id;
        order.stageName = this.constStageName[order.stage + ""]
          ? this.constStageName[order.stage + ""]
          : "未知类型";
        order.productLineName = "订单暂无产线信息";
        this.orderDetailData = order;
        this.getProductLine();
      });
    },
    // 获取订单相关的产线数据
    getProductLine() {
      axios
        .get(
          `${uris.productLine}?orderId=${this.orderId}&pageSize=1` // 订单查询降序排列，取一条即可
        )
        .then((res) => {
          const {
            data: {
              data: { list },
              code,
            },
          } = res;
          if (code === 0 && list.length > 0) {
            const productLineName = list[0].name;
            const workflow = list[0].workflow;
            this.workflowId = workflow.id;
            this.$set(this.orderDetailData, "productLineName", productLineName);
            this.getProcessData();
          }
        });
    },
    //获取订单相关步骤（制程相关）列表
    getProcessData() {
      axios
        .get(
          `${uris.process}?workflowId=${this.workflowId}&groupId =${this.groupId}&pageSize=200`
        )
        .then((res) => {
          const {
            data: {
              data: { list },
            },
          } = res;
          this.processData = list;
        });
    },
    // 获取相关的工序
    getProedureByProcess() {},
    // 订单名称弹窗
    onEdit() {
      this.editDialogVisible = true;
    },
    // 订单名称修改提交
    onEditSubmit() {
      axios
        .put(`${uris.order}/${this.orderId}`, {
          name: this.orderDetailData.name,
        })
        .then((res) => {
          this.$message({
            type: res.data.code !== 0 ? "error" : "success",
            message: res.data.code !== 0 ? res.data.message : "修改成功",
          });
          this.editDialogVisible = false;
        });
    },
    // 手动完成确认提示框
    manualFinishConfirm() {
      this.$confirm("操作后订单将被标记完成状态，是否确认", "手动完成", {
        callback: (action) => {
          if ("confirm" === action) {
            this.orderFinish();
          }
        },
      });
    },
    //订单完成
    orderFinish() {
      axios
        .put(`${uris.order}/${this.orderId}`, {
          state: 2,
        })
        .then((res) => {
          this.$message({
            type: res.data.code !== 0 ? "error" : "success",
            message: res.data.code !== 0 ? res.data.message : "修改成功",
          });
        });
    },
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>
