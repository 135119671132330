var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-bottom":"0px"}},[_c('Breadcrumb',{attrs:{"home":false,"breadcrumbs":[
      {
        label: '订单管理',
        to: '/order',
      },
      {
        label: '详情',
        to: ("/order/" + (this.$route.params.id)),
      } ]}}),_c('el-card',{staticClass:"card-common",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("基本信息")]},proxy:true}])},[_c('InfoPanel',{attrs:{"schema":_vm.orderDetailSchema,"data":_vm.orderDetailData},scopedSlots:_vm._u([{key:"name",fn:function(ref){
    var value = ref.value;
return [_c('div',[_vm._v(_vm._s(value))]),_c('el-link',{staticClass:"edit-btn",attrs:{"type":"primary"},on:{"click":_vm.onEdit}},[_vm._v(" 编辑 ")])]}}])}),_c('el-dialog',{attrs:{"title":"编辑","visible":_vm.editDialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.editDialogVisible=$event}}},[_c('el-form',{attrs:{"model":_vm.orderDetailData,"label-width":"80px"}},[_c('el-form-item',{staticStyle:{"margin":"0"},attrs:{"label":"请输入订单名称"}},[_c('el-input',{model:{value:(_vm.orderDetailData.name),callback:function ($$v) {_vm.$set(_vm.orderDetailData, "name", $$v)},expression:"orderDetailData.name"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onEditSubmit}},[_vm._v("提交")])],1)],1)],1),_c('el-card',{staticClass:"card-common",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("订单进度")]},proxy:true}])},[_c('div',{staticClass:"process-progress"},[_c('OrderProgress',{attrs:{"orderId":_vm.orderId,"processData":_vm.processData}})],1)]),_c('el-card',{staticClass:"card-common",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("MAC/SN")]},proxy:true}])},[_c('div',{staticClass:"mac-sn-wall"},[_c('MACSN',{attrs:{"orderId":_vm.orderId}})],1)]),_c('el-card',{staticClass:"card-common",scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("制程图")]},proxy:true}])},[_c('div',{staticClass:"process-wall"},[_c('WorkflowScroll',{attrs:{"processData":_vm.processData}})],1)]),_c('div',{staticStyle:{"height":"72px","background-color":"#fff","margin":"10px -20px -32px -20px","line-height":"72px"}},[_c('el-button',{staticStyle:{"margin-left":"40px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.manualFinishConfirm()}}},[_vm._v("手动完成")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }