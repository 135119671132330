<template>
  <div class="scroll-card-box">
    <div class="scroll-box">
      <div
        v-if="showLeftBtn"
        class="scroll-left-btn"
        :class="{ 'more-line': isLeftMoreLine }"
        @click="onPre"
      >
        <i class="el-icon-arrow-left" />
      </div>
      <div class="scroll-view" ref="scrollViewRef">
        <div class="scroll-slider" :style="{ left: sliderLeft }">
          <div
            class="scroll-item"
            :class="{ active: index === i }"
            :style="itemStyle"
            v-for="(item, i) of steps"
            :key="i"
            @click="onClick(i)"
          >
            <div class="item-container">
              <div class="item-order">{{ i + 1 }}</div>
              <div class="item-name">
                {{ item.name }}
              </div>
              <div class="item-id">
                {{ item.id }}
              </div>
            </div>
            <div
              class="link-line"
              v-if="i < steps.length - 1"
              :style="{
                width: `${levelLineWidth}px`,
                right: `-${levelLineWidth - 4}px`,
              }"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-if="showRightBtn"
        class="scroll-right-btn"
        :class="{ 'more-line': isRightMoreLine }"
        @click="onNext"
      >
        <i class="el-icon-arrow-right" />
      </div>

      <!-- 链接卡片和内容的线 -->
      <div class="active-lines-box" >
        <div class="active-lines" :style="{ left: sliderLeft }">
          <div
            class="active-line-box"
            v-for="(it, i) of steps"
            :key="i"
            :style="itemStyle"
          >
            <div v-if="index === i" class="active-line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-card-container">
      <div
        class="scroll-child-item"
        :class="{ active: index === i }"
        v-for="(child, i) of children"
        :key="i"
      >
        <div class="item-order">{{ i + 1 }}</div>
        <div class="item-name">
          {{ child.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const itemMarginRight = 72;

    return {
      index: 0,
      itemWidth: 190,
      itemMarginRight,
      itemOffset: 0,
      sliderLeft: 0,
      levelLineWidth: itemMarginRight + 8,
      moreLineWidth: 28,
    };
  },
  mounted() {
    this.setItemOffset();
  },
  computed: {
    children() {
      return this.steps[this.index]?.children || [];
    },
    stepsLen() {
      return this.steps.length;
    },
    itemStyle() {
      return {
        width: `${this.itemWidth}px`,
        marginRight: `${this.itemMarginRight}px`,
      };
    },
    activeLineIndex() {
      // 超出 4 个时，前 len - 3 项 垂直防线虚线 都在第一个，len - 3 项后面按照顺序切换。
      // （即：数据最后一项也出现在最右侧时的切换逻辑）
      if (this.index < this.stepsLen - 3) {
        return 0;
      }
      return 4 - (this.stepsLen - this.index);
    },
    activeLinesStyle() {
      const style = {};
      if (this.isLeftMoreLine) {
        style.left = `${this.moreLineWidth}px`;
      }
      return style;
    },
    showLeftBtn() {
      return this.stepsLen > 4 && this.index > 0;
    },
    showRightBtn() {
      return this.stepsLen > 4 && this.index !== this.stepsLen - 1;
    },
    isLeftMoreLine() {
      return this.stepsLen > 4 && this.index > 0;
    },
    isRightMoreLine() {
      return this.stepsLen > 4 && this.index < this.stepsLen - 4;
    },
  },
  methods: {
    onClick(i) {
      if (this.index === i) {
        return;
      }
      this.index = i;
      this.handleScroll();
    },
    onPre() {
      let index = this.index;
      index -= 1;
      this.index = index >= 0 ? index : this.stepsLen - 1;
      this.handleScroll();
    },
    onNext() {
      let index = this.index;
      index += 1;
      if (index) {
      }
      this.index = index < this.stepsLen ? index : 0;
      this.handleScroll();
    },
    setItemOffset() {
      const scrollView = this.$refs.scrollViewRef;
      if (!scrollView) {
        this.itemOffset = 0;
        return 0;
      }

      let moreLineWidth = 0;
      if (this.isLeftMoreLine) {
        moreLineWidth += this.moreLineWidth;
      }
      if (this.isRightMoreLine) {
        moreLineWidth += this.moreLineWidth;
      }

      const width = parseFloat(window.getComputedStyle(scrollView).width);
      const { itemMarginRight } = this;
      const itemWidth = (width - itemMarginRight * 3 - moreLineWidth) / 4;
      this.itemWidth = itemWidth;
      this.itemOffset = itemWidth + itemMarginRight;
    },
    handleScroll() {
      if (this.stepsLen <= 4) {
        return;
      }
      this.setItemOffset();
      const {
        stepsLen,
        index,
        itemOffset,
        isLeftMoreLine,
        moreLineWidth,
      } = this;
      let left = 0;
      if (isLeftMoreLine) {
        left -= moreLineWidth;
      }
      // 当处于最后4项的情况
      if (index >= stepsLen - 4) {
        left += (stepsLen - 4) * itemOffset;
        this.sliderLeft = `-${left}px`;
        return;
      }
      left += index * itemOffset;
      this.sliderLeft = `-${left}px`;
    },
  },
};
</script>

<style lang="less">
@marginBottom: 40px;
@itemHeight: 161px;

.scroll-card-box {
  position: relative;
  width: 90vw;
  padding: 20px 40px;
  background-color: #fff;

  .scroll-box {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: @marginBottom;
    height: @itemHeight;
    box-sizing: border-box;

    .scroll-left-btn,
    .scroll-right-btn {
      position: absolute;
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: @itemHeight;
      font-size: 27px;
      background-color: #fff;
      color: rgba(0, 110, 187, 1);
      user-select: none;
      cursor: pointer;
      /* 渐变覆盖层 */
      &.more-line::after {
        content: "";
        width: 28px;
        height: @itemHeight;
        position: absolute;
        top: 0;
      }
    }
    .scroll-left-btn {
      left: -32px;
      &.more-line::after {
        right: -36px;
        background: linear-gradient(90deg, #fff, transparent);
      }
    }
    .scroll-right-btn {
      right: -32px;
      &.more-line::after {
        left: -36px;
        background: linear-gradient(90deg, transparent, #fff);
      }
    }

    .scroll-view {
      .scroll-slider {
        position: absolute;
      }
    }

    /* 链接卡片和内容的线 */
    .active-lines-box {
      position: absolute;
      bottom: -@marginBottom + 2px;
      width: 100%;
      height: @marginBottom + 4px;
      overflow: hidden;
    }
    .active-lines {
      position: absolute;
      // bottom: 0;
      // width: 100%;
      height: 0px;
      line-height: 0;
      font-size: 0;
      white-space: nowrap;
      transition: left 1s;
      .active-line-box {
        position: relative;
        display: inline-block;
      }
      .active-line {
        position: absolute;
        left: 50%;
        bottom: -@marginBottom - 4px;
        z-index: 1;
        height: @marginBottom + 4px;
        border-left: 2px dashed #006ebb;
        &::after {
          content: "";
          position: absolute;
          left: -5px;
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #006ebb;
        }
        &::after {
          bottom: 0;
        }
      }
    }
  }

  .scroll-view {
    position: relative;
    width: 100%;
    overflow: hidden;
    .scroll-slider {
      white-space: nowrap;
      transition: left 1s;
      .scroll-item {
        display: inline-block;
        position: relative;
        height: @itemHeight;
        border-radius: 8px;
        border: 1px solid rgba(235, 238, 245, 1);
        cursor: pointer;
        box-sizing: border-box;

        .item-container {
          padding: 20px;
          .item-order {
            display: inline-block;
            margin-bottom: 20px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            border-radius: 50%;
            color: #fff;
            background-color: #006ebb;
          }
          .item-name {
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: 500;
          }
          .item-id {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }

        &.active {
          color: #fff;
          background-color: #006ebb;
          .item-container {
            .item-order {
              color: #006ebb;
              background-color: #fff;
            }
          }
        }

        .link-line {
          position: absolute;
          top: 50%;
          z-index: 1;
          border-top: 2px dashed #ccc;
          &::before,
          &::after {
            content: "";
            position: absolute;
            top: -5px;
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ccc;
          }

          &::after {
            right: 0;
          }
        }
      }
    }
  }

  .scroll-card-container {
    padding: 20px;
    border-radius: 12px;
    border: 2px dashed rgba(235, 238, 245, 1);
    .scroll-child-item {
      display: inline-block;
      margin: 0 20px 20px 0;
      padding: 12px;
      border-radius: 12px;
      background-color: rgba(245, 247, 250, 1);
      &.active {
        .item-order {
        }
      }
      .item-order {
        display: inline-block;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
        color: #fff;
        background-color: #006ebb;
      }
      .item-name {
        display: inline-block;
        font-size: 14px;
        color: rgba(96, 98, 104, 1);
      }
    }
  }
}
</style>
