<template>
  <div>
    <enn-bar
      ref="barRef"
      :datas="datas"
      :options="options"
      :style="chartStyle"
      v-if="isShow"
    />
    <div v-else class="empty-data">
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { uris } from "../../../api/uri";
export default {
  props: {
    orderId: {
      type: String,
    },
    processData: {
      type: Array,
    },
  },
  data() {
    // const xData = [
    //   "1.SN抹除",
    //   "2.信息烧录",
    //   "3.SN扫码核验",
    //   "4.功能测试",
    //   "5.SN抹除",
    // ];
    // const data = [0, 79, 98, 90, 0];
    return {
      chartStyle: {
        width: "720px",
        height: "276px",
      },
      datas: {
        name: ["订单进度"],
        x: this.xData,
        data: this.data,
      },
      options: {},
      times: [], //执行时间
      counts: [], // 执行次数 // 总次数是设备数量
      xData: [], // 步骤
      data: [], // 百分比数据

      isShow: false,
    };
  },
  created() {
    if (this.processData && this.processData.length > 0) {
      this.getOrderExecTimeProgress();
    }
  },
  methods: {
    async getOrderExecTimeProgress() {
      const _times = []; //执行时间
      const _counts = []; // 执行次数 // 总次数是设备数量
      const _xData = []; // 步骤
      const _data = []; // 百分比数据
      // 处理process 数据。
      const _processMap = new Map();
      this.processData.map((processitem, index) => {
        _xData[index] = `${index + 1}. ${processitem.name}`;
        _times[index] = 0;
        _counts[index] = 0;
        _times[index] = 0;
        _data[index] = 0;
        _processMap.set(processitem.id, index);
      });
      const res = await axios.get(
        `${uris.order}/exeTime?orderId=${this.orderId}`
      );
      const {
        data: {
          data: { list },
        },
        code,
      } = res;
      if (code !== 0) {
        for (let i = 0; i < list.length; i++) {
          const {
            exeCount,
            totalTime,
            processFinishCount,
            order: { productCount },
            process: { id: processId },
          } = list[i];
          // 获取步骤的顺序处理数据下标。
          const _index = _processMap.get(processId);
          _times[_index] = totalTime;
          _counts[_index] = exeCount;
          _data[_index] = (processFinishCount * 100 /  productCount).toFixed(1);
         }
        this.times = _times;
        this.counts = _counts;
        this.xData = _xData;
        this.data = _data;
        this.datas.x = this.xData;
        this.datas.data = this.data;
        if (_xData.length > 0) {
          // 步骤是衡量标准
          this.isShow = true;
          this.setOptions();
        }
      }
    },
    setOptions() {
      let offsetX = parseInt(this.chartStyle.width);
      offsetX = offsetX - offsetX * 0.2 - 25;
      const options = {
        color: ["rgba(0, 110, 187, 1)"],
        grid: {
          top: 0,
          right: "6%",
          left: "10%",
        },
        tooltip: {
          position(point, params, dom, rect, size) {
            return {
              left: point[0] - 28,
              top: point[1] - size.contentSize[1] - 8,
            };
          },
          formatter: (params) => {
            const { dataIndex } = params[0];
            return `<div class="bar-tooltip-row">功能测试：${this.times[dataIndex]}(ms)</div><div class="bar-tooltip-row">执行次数：${this.counts[dataIndex]}</div><div class="bar-tooltip-triangle"></div>`;
          },
        },
        label: {
          show: true,
          position: "left",
          formatter: "{c}%",
          offset: [offsetX, 0],
        },
        xAxis: {
          type: "value", 
          max:100, 
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: this.datas.x,
          inverse: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(235, 238, 245, 0.9)",
            },
          },
          axisPointer: {
            type: "none",
          },
        },
        itemStyle: {
          borderRadius: [0, 20, 20, 0],
        },
        series: [
          {
            type: "bar",
            data: this.datas.data,
            barMinWidth: 20,
            showBackground: true,
            legendHoverLink: false,
          },
        ],
      };
      this.options = options;
    },
  },
  watch: {
    processData(newData, oldVal) {
      this.processData = newData;
      this.getOrderExecTimeProgress();
    },
  },
};
</script>

<style lang="less">
.bar-tooltip-row {
  color: rgba(51, 51, 51, 1);
}
.bar-tooltip-triangle {
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 20px;
    bottom: -6px;
    border-top: 8px solid #fff;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
  &::before {
    bottom: -7px;
    border-top-color: rgba(227, 231, 237, 1);
  }
  .empty-data {
    height: 360px;
    line-height: 360px;
    text-align: center;
    color: #999;
  }
}
</style>
