<template>
  <div>
    <ScrollCard v-if="isShow" :steps="steps" />
    <div v-else class="empty-data">
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { uris } from "../../../api/uri";
import ScrollCard from "./ScrollCard.vue";

export default {
  props: { processData: { type: Array } },
  components: {
    ScrollCard,
  },
  data() {
    // const steps = [
    //   {
    //     id: "数据id",
    //     name: "数据名称",
    //     children: [
    //       {
    //         name: "数据名称",
    //       },
    //     ],
    //   },
    // ];
    return {
      steps: [],
      isShow: false,
    };
  },
  created() {
    if (this.processData && this.processData.length > 0) {
      this.constuctData();
    }
  },
  methods: {
    async constuctData() {
      const _steps = [];
      for (let i = 0; i < this.processData.length; i++) {
        const process = this.processData[i];
        const step = {
          id: process.id,
          name: process.name,
          children: [],
        };
        const procedurelist = await this.getProcedureData(process.id);
        step.children = procedurelist;
        _steps.push(step);
      }
      this.steps = _steps;
      this.isShow = true;
    },
    async getProcedureData(processId) {
      const res = await axios.get(
        `${uris.procedure}?processId=${processId}&pageSize=200`
      );
      const {
        data: {
          data: { list },
        },
      } = res;
      return list;
    },
  },
  watch: {
    processData(newVal, oldVal) {
      // 主要为了解决processData改动但是不重新渲染的问题
      this.processData = newVal;
      this.constuctData();
    },
  },
};
</script>

<style lang="less">
.empty-data {
  height: 360px;
  line-height: 360px;
  text-align: center;
  color: #999;
}
</style>
