<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="macsn-wall">
          <div style="float: left">
            <div class="icon-common">SN</div>
            <span class="detail-text">SN数量: {{ snData.length }}</span>
          </div>
          <div style="float: right" class="mac-sn-oper">
            <el-icon class="el-icon-download" /><span
              class="mac-sn-oper-text"
              @click="exportXlSX('sn')"
              >导出</span
            >
            <el-icon class="el-icon-search" /><span
              class="mac-sn-oper-text"
              @click="goMACAndSN('sn')"
              >查看</span
            >
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="macsn-wall">
          <div style="float: left">
            <div class="icon-common">MAC</div>
            <span class="detail-text">MAC数量: {{ macData.length }}</span>
          </div>
          <div style="float: right" class="mac-sn-oper">
            <el-icon class="el-icon-download" /><span
              class="mac-sn-oper-text"
              @click="exportXlSX('mac')"
              >导出</span
            >
            <el-icon class="el-icon-search" /><span
              class="mac-sn-oper-text"
              @click="goMACAndSN('mac')"
              >查看</span
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { uris } from "../../../api/uri";
import axios from "axios"; 
import * as XLSX from "xlsx";
import moment from "moment";
export default {
  props: {
    orderId: {
      type: String,
    },
  },
  data() {
    return {
      macData: [],
      snData: [],
    };
  },
  created() {
    this.getMacDataByOrder();
    this.getSnDataByOrder();
  },
  methods: {
    getMacDataByOrder() {
      axios
        .get(`${uris.mac}?pageNumber=1&pageSize=2000&orderId=${this.orderId}`)
        .then((res) => {
          const {
            data: {
              data: { list },
            },
          } = res;
          this.macData = list;
        });
    },
    getSnDataByOrder() {
      axios
        .get(`${uris.sn}?pageNumber=1&pageSize=2000&orderId=${this.orderId}`)
        .then((res) => {
          const {
            data: {
              data: { list },
            },
          } = res;
          this.snData = list;
        });
    },
    goMACAndSN(dir) {
      this.$router.push({
        name: dir,
      });
    },

    exportXlSX(title) {
      console.log(title);
      const _xlsxdata = title === "sn" ? this.snData : this.macData;
      const xlsxdata =
        title === "sn"
          ? [["SN编号", "SN值", "所属订单", "所属产品", "创建时间"]]
          : [["MAC编号", "MAC值", "所属订单", "所属产品", "创建时间"]];
      for (let i = 0; i < _xlsxdata.length; i++) {
        const element = _xlsxdata[i];
        const order = element.order;
        const product = element.order.product;
        xlsxdata.push([
          element.id,
          title === "sn" ? element.sn : element.mac,
          order.name,
          product.name,
          this.dateFormat(element.created_at),
        ]);
      }
      const ws = XLSX.utils.aoa_to_sheet(xlsxdata);

      /* generate workbook and add the worksheet */
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      /* save to file */
      XLSX.writeFile(wb, `${this.orderId}-${title}.xlsx`);
    },
    dateFormat(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>

<style lang="less">
.icon-common {
  display: inline-block;
  background-image: url("../../../assets/images/doc_mac_sn27.png");
  background-size: cover;
  width: 36px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #006ebb;
  font-weight: 1000;
  font-size: 12px;
  margin: 0 20px 0 20px;
}
.detail-text {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  color: rgba(66, 66, 66, 1);
  text-align: left;
}

.macsn-wall {
  background-color: #f5f7fa;
  height: 80px;
  border-radius: 8px;
  line-height: 80px;
}
.mac-sn-oper {
  color: #006ebb;
  font-size: 14px;
  font-weight: 400;
}
.mac-sn-oper-text {
  margin-right: 20px;
  cursor: pointer;
}
</style>
